var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-risks-page"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-12"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block block-rounded bg-image air-quality-bg"
  }, [_c('div', {
    staticClass: "block-content d-flex justify-content-between pt-4 pb-7 text-white bg-black-50"
  }, [_c('div', [_c('p', {
    staticClass: "font-size-h3 font-w600 mb-0"
  }, [_vm._v("Air Quality Index")]), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.location && !_vm.loadingAction.airQuality ? _c('p', {
    staticClass: "text-white-75 mb-0"
  }, [_c('i', {
    staticClass: "fa fa-location-dot fa-fw"
  }), _vm._v(" " + _vm._s(_vm.asset.recentAirQuality.location.name) + " (" + _vm._s(_vm.airStationDistance) + ") ")]) : _vm._e(), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index && !_vm.loadingAction.airQuality ? _c('p', {
    staticClass: "text-white-75 mb-0"
  }, [_c('i', {
    staticClass: "fa fa-clock fa-fw"
  }), _vm._v(" Last updated " + _vm._s(_vm._f("date")(_vm.asset.recentAirQuality.lastMeasured, 'DD/MM/YYYY HH:mm')) + " ")]) : !_vm.loadingAction.airQuality ? _c('p', {
    staticClass: "text-white-75 mb-0"
  }, [_vm._v("No nearby air quality data found")]) : _vm._e()]), _vm.loadingAction.airQuality ? _c('div', {
    staticClass: "text-right"
  }, [_vm._m(0)]) : _vm._e(), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index ? _c('div', {
    staticClass: "air-quality-rating badge mb-0 text-right",
    style: {
      'background-color': _vm.asset.recentAirQuality.indexColour
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.asset.recentAirQuality.index) + " ")]), _c('span', [_vm._v(_vm._s(_vm.asset.recentAirQuality.indexRating))])]) : _vm._e()]), _vm.asset.recentAirQuality && _vm.asset.recentAirQuality.index ? _c('div', {
    staticClass: "block-content p-0 bg-body-light"
  }, [_c('div', {
    staticClass: "row no-gutters text-center"
  }, _vm._l(_vm.airQualityData, function (qualityType, code) {
    return _c('div', {
      key: code,
      staticClass: "col-xl-2 col-4 border-right border-bottom p-3",
      attrs: {
        "title": qualityType.description
      }
    }, [_c('p', {
      staticClass: "font-size-sm font-w600 text-uppercase mb-1"
    }, [_vm._v(" " + _vm._s(qualityType.name) + " ")]), _c('div', {
      staticClass: "font-size-lg"
    }, [_c('strong', {
      style: {
        color: qualityType.colour
      }
    }, [_vm._v(_vm._s(qualityType.value || 'N/A'))]), _c('br')])]);
  }), 0), _c('div', {
    staticClass: "row no-gutters text-center"
  }, _vm._l(_vm.weatherData, function (qualityType, code) {
    return _c('div', {
      key: code,
      staticClass: "col-xl-3 col-6 border-right border-bottom p-3",
      attrs: {
        "title": qualityType.description
      }
    }, [_c('p', {
      staticClass: "font-size-sm font-w600 text-uppercase mb-1"
    }, [_vm._v(" " + _vm._s(qualityType.name) + " ")]), _c('div', {
      staticClass: "font-size-lg"
    }, [_c('strong', {
      style: {
        color: qualityType.colour
      }
    }, [_vm._v(_vm._s(qualityType.value || 'N/A'))])])]);
  }), 0)]) : _vm._e()])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-6"
  }, [_c('div', {
    staticClass: "block block-rounded block-border"
  }, [_vm._m(1), _c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('table', {
    staticClass: "table table-borderless table-striped table-hover"
  }, [_c('tbody', [_vm._l(_vm.floodRisks, function (risk, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('strong', [_c('a', {
      attrs: {
        "target": "_blank",
        "href": "".concat(risk.url, ".html")
      }
    }, [_vm._v(_vm._s(risk.label))])])])]);
  }), _vm.floodRisks.length === 0 ? _c('tr', [_c('td', [_vm._v("No flood risks found")])]) : _vm._e()], 2)]), !_vm.showFloodMap ? _c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "btn btn-outline-primary mb-3",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleFloodRiskMap($event);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-map fa-fw"
  }), _vm._v(" Show Map")])]) : _vm._e(), _vm.showFloodMap ? _c('div', [_vm.loadingAction.floodRisks || !_vm.fullFloodRisks ? _c('div', {
    staticClass: "text-center"
  }, [_vm._m(2)]) : _c('GoogleMap', {
    attrs: {
      "map-config": _vm.floodMapOptions,
      "markers": _vm.floodMapMarkers,
      "geometry": _vm.floodMapGeometry
    }
  })], 1) : _vm._e()])])]), _c('div', {
    staticClass: "col-xl-6"
  }, [_c('div', {
    staticClass: "block block-rounded block-border"
  }, [_vm._m(3), _c('div', {
    staticClass: "block-content block-content-full"
  }, [_vm.loadingAction.crime ? _c('div', {
    staticClass: "text-center"
  }, [_vm._m(4)]) : _c('table', {
    staticClass: "table table-borderless table-striped table-hover"
  }, [_c('tbody', [_vm._l(_vm.groupedCrimes, function (group) {
    return _c('tr', {
      key: group.category
    }, [_c('td', [_vm._v(" " + _vm._s(group.count) + " x "), _c('strong', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(group.category))])])]);
  }), _vm.groupedCrimes.length === 0 ? _c('tr', [_c('td', [_vm._v("No crimes found")])]) : _vm._e()], 2)])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-white",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-content d-flex justify-content-between align-items-center py-5 text-white bg-gd-default"
  }, [_c('div', [_c('p', {
    staticClass: "font-size-h4 font-w700 mb-0"
  }, [_vm._v("Nearby Flood Risks")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Click a row for more information")])]), _c('p', {
    staticClass: "text-right mb-0"
  }, [_c('i', {
    staticClass: "fa fa-water fa-3x"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-secondary",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-content d-flex justify-content-between align-items-center py-5 text-white bg-danger"
  }, [_c('div', [_c('p', {
    staticClass: "font-size-h4 font-w700 mb-0"
  }, [_vm._v("Recent Crime")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Recent crime reported in the close vicinity of this asset")])]), _c('p', {
    staticClass: "text-right mb-0"
  }, [_c('i', {
    staticClass: "fa fa-siren-on fa-3x"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-secondary",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);
}]

export { render, staticRenderFns }