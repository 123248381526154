<template>
  <div data-cy="asset-risks-page">
    <div class="row">
      <div class="col-xl-12">
        <div class="block block-rounded">
          <div class="block block-rounded bg-image air-quality-bg">
            <div class="block-content d-flex justify-content-between pt-4 pb-7 text-white bg-black-50">
              <div>
                <p class="font-size-h3 font-w600 mb-0">Air Quality Index</p>

                <p v-if="asset.recentAirQuality && asset.recentAirQuality.location && !loadingAction.airQuality" class="text-white-75 mb-0">
                  <i class="fa fa-location-dot fa-fw" /> {{ asset.recentAirQuality.location.name }} ({{ airStationDistance }})
                </p>

                <p v-if="asset.recentAirQuality && asset.recentAirQuality.index && !loadingAction.airQuality" class="text-white-75 mb-0">
                  <i class="fa fa-clock fa-fw" /> Last updated {{ asset.recentAirQuality.lastMeasured | date('DD/MM/YYYY HH:mm') }}
                </p>

                <p v-else-if="!loadingAction.airQuality" class="text-white-75 mb-0">No nearby air quality data found</p>
              </div>

              <div v-if="loadingAction.airQuality" class="text-right">
                <div class="spinner-border spinner-lg text-white" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <div
                v-if="asset.recentAirQuality && asset.recentAirQuality.index"
                class="air-quality-rating badge mb-0 text-right"
                :style="{ 'background-color': asset.recentAirQuality.indexColour }"
              >
                <strong>{{ asset.recentAirQuality.index }} </strong>
                <span>{{ asset.recentAirQuality.indexRating }}</span>
              </div>
            </div>
            <div v-if="asset.recentAirQuality && asset.recentAirQuality.index" class="block-content p-0 bg-body-light">
              <div class="row no-gutters text-center">
                <div
                  v-for="(qualityType, code) in airQualityData"
                  :key="code"
                  class="col-xl-2 col-4 border-right border-bottom p-3"
                  :title="qualityType.description"
                >
                  <p class="font-size-sm font-w600 text-uppercase mb-1">
                    {{ qualityType.name }}
                  </p>
                  <div class="font-size-lg">
                    <strong :style="{ color: qualityType.colour }">{{ qualityType.value || 'N/A' }}</strong
                    ><br />
                  </div>
                </div>
              </div>
              <div class="row no-gutters text-center">
                <div
                  v-for="(qualityType, code) in weatherData"
                  :key="code"
                  class="col-xl-3 col-6 border-right border-bottom p-3"
                  :title="qualityType.description"
                >
                  <p class="font-size-sm font-w600 text-uppercase mb-1">
                    {{ qualityType.name }}
                  </p>
                  <div class="font-size-lg">
                    <strong :style="{ color: qualityType.colour }">{{ qualityType.value || 'N/A' }}</strong>
                  </div>
                </div>
              </div>
            </div>

            <!-- <table v-else class="table table-borderless table-striped table-hover">
              <tbody>
                <tr>
                  <td><strong>Overall Quality</strong> {{ asset.recentAirQuality.index }} - {{ asset.recentAirQuality.indexRating }}</td>
                </tr>
                <tr v-for="(value, type) in asset.recentAirQuality.details" :key="type">
                  <td>
                    <strong class="text-capitalize">{{ type }}</strong> {{ value.v }}
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="block block-rounded block-border">
          <div class="block-content d-flex justify-content-between align-items-center py-5 text-white bg-gd-default">
            <div>
              <p class="font-size-h4 font-w700 mb-0">Nearby Flood Risks</p>
              <p class="mb-0">Click a row for more information</p>
            </div>
            <p class="text-right mb-0">
              <i class="fa fa-water fa-3x"></i>
            </p>
          </div>
          <div class="block-content block-content-full">
            <table class="table table-borderless table-striped table-hover">
              <tbody>
                <tr v-for="(risk, index) in floodRisks" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <strong
                      ><a target="_blank" :href="`${risk.url}.html`">{{ risk.label }}</a></strong
                    >
                  </td>
                </tr>
                <tr v-if="floodRisks.length === 0">
                  <td>No flood risks found</td>
                </tr>
              </tbody>
            </table>

            <div v-if="!showFloodMap" class="text-center">
              <a href="#" class="btn btn-outline-primary mb-3" @click.prevent="toggleFloodRiskMap"><i class="fa fa-map fa-fw"></i> Show Map</a>
            </div>

            <div v-if="showFloodMap">
              <div v-if="loadingAction.floodRisks || !fullFloodRisks" class="text-center">
                <div class="spinner-border spinner-lg text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <GoogleMap v-else :map-config="floodMapOptions" :markers="floodMapMarkers" :geometry="floodMapGeometry" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="block block-rounded block-border">
          <div class="block-content d-flex justify-content-between align-items-center py-5 text-white bg-danger">
            <div>
              <p class="font-size-h4 font-w700 mb-0">Recent Crime</p>
              <p class="mb-0">Recent crime reported in the close vicinity of this asset</p>
            </div>
            <p class="text-right mb-0">
              <i class="fa fa-siren-on fa-3x"></i>
            </p>
          </div>
          <div class="block-content block-content-full">
            <div v-if="loadingAction.crime" class="text-center">
              <div class="spinner-border spinner-lg text-secondary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table v-else class="table table-borderless table-striped table-hover">
              <tbody>
                <tr v-for="group in groupedCrimes" :key="group.category">
                  <td>
                    {{ group.count }} x <strong class="text-capitalize">{{ group.category }}</strong>
                  </td>
                </tr>
                <tr v-if="groupedCrimes.length === 0">
                  <td>No crimes found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMap from '@/components/GoogleMap';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AssetRisk',
  components: {
    GoogleMap
  },
  data() {
    return {
      showFloodMap: false
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      fullFloodRisks: 'asset/floodRisks',
      loadingAction: 'asset/loadingAction'
    }),
    floodRisks() {
      return this.fullFloodRisks.length > 0 ? this.fullFloodRisks : this.asset.floodRisks;
    },
    groupedCrimes() {
      const groups = {};

      this.asset.recentCrimes.forEach(item => {
        const category = item.category.replace('-', ' ');
        groups[category] = groups[category] ? groups[category] + 1 : 1;
      });

      const grouped = Object.keys(groups).map(category => ({
        category,
        count: groups[category]
      }));

      grouped.sort((a, b) => b.count - a.count);

      return grouped;
    },
    airStationDistance() {
      const { geo: stationLocation } = this.asset.recentAirQuality.location;

      if (!stationLocation || !stationLocation[0]) return null;

      const stationGeo = {
        lat: stationLocation[0],
        lng: stationLocation[1]
      };

      if (!this.asset.address.latitude || !this.asset.address.longitude) return null;

      const distance = this.distance(this.asset.address.latitude, this.asset.address.longitude, stationGeo.lat, stationGeo.lng);

      if (!distance) return 'N/A';

      const distanceMiles = distance * 0.621371;

      return `${distanceMiles.toFixed(2)} miles from asset`;
    },
    airQualityData() {
      const { pm25, pm10, o3, no2, so2, co } = this.asset.recentAirQuality.details;

      return { pm25, pm10, o3, no2, so2, co };
    },
    weatherData() {
      const { t, p, h, w } = this.asset.recentAirQuality.details;
      return { t, p, h, w };
    },
    floodMapOptions() {
      return {
        center: { lat: parseFloat(this.asset.address.latitude), lng: parseFloat(this.asset.address.longitude) },
        zoom: 18,
        mapTypeId: 'satellite'
      };
    },
    floodMapMarkers() {
      return [
        {
          pos: {
            lat: parseFloat(this.asset.address.latitude),
            lng: parseFloat(this.asset.address.longitude)
          },
          label: {
            text: '\uf1ad', // codepoint from https://fonts.google.com/icons
            fontFamily: '"Font Awesome 6 Pro"',
            fontWeight: '900',
            color: '#65c198',
            fontSize: '18px'
          },
          icon: {
            path:
              'M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeWeight: 3,
            strokeColor: '#65c198',
            rotation: 0,
            scale: 0.09,
            labelOrigin: [195, 200],
            anchor: [195, 520]
          },
          title: this.asset.siteName
        },
        ...this.fullFloodRisks.map((risk, index) => ({
          pos: {
            lat: parseFloat(risk.latitude),
            lng: parseFloat(risk.longitude)
          },
          label: {
            text: String(index + 1), // codepoint from https://fonts.google.com/icons
            fontWeight: '900',
            color: '#ffffff',
            fontSize: '18px'
          },
          icon: {
            path:
              'M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z',
            fillColor: this.floodMapColors[index],
            fillOpacity: 0.7,
            strokeWeight: 2,
            strokeColor: '#ffffff',
            rotation: 0,
            scale: 0.08,
            labelOrigin: [195, 200],
            anchor: [195, 520]
          },
          title: risk.label
        }))
      ];
    },
    floodMapColors() {
      return ['#e0b323', '#d03f3f', '#11aae9', '#aa38da', '#23e02a', '#ffffff'];
    },
    floodMapGeometry() {
      if (!this.fullFloodRisks || this.fullFloodRisks.length === 0) return [];

      return this.fullFloodRisks
        .filter(risk => risk.polygonData && risk.polygonData[0]?.geometry?.coordinates)
        .reduce((acc, risk, index) => {
          const mappedCoords = risk.polygonData[0].geometry.coordinates.reduce((allCoords, coords) => {
            if (risk.polygonData[0].geometry.type === 'Polygon') {
              return [
                ...allCoords,
                {
                  coordinates: coords.map(test => ({ lng: test[0], lat: test[1] })),
                  title: risk.label,
                  colour: this.floodMapColors[index]
                }
              ];
            } else if (risk.polygonData[0].geometry.type === 'MultiPolygon') {
              return [
                ...allCoords,
                ...coords.map((c, cIdx) => ({
                  coordinates: c.map(test => ({ lng: test[0], lat: test[1] })),
                  title: `${risk.label} (Area ${cIdx + 1})`,
                  colour: this.floodMapColors[index]
                }))
              ];
            } else {
              return allCoords;
            }
          }, []);

          return [...acc, ...mappedCoords];
        }, []);
    }
  },
  created() {
    if (!this.asset.recentCrimes.length) {
      this.getCrime({ id: this.$route.params.id });
    }

    if (!this.asset.recentAirQuality.index) {
      this.getAirQuality({ id: this.$route.params.id });
    }
  },
  methods: {
    ...mapActions({
      getCrime: 'asset/crime',
      getAirQuality: 'asset/airQuality',
      getFloodRisks: 'asset/floodRisks'
    }),
    toggleFloodRiskMap() {
      this.showFloodMap = true;
      this.getFloodRisks({ id: this.$route.params.id, params: { polygon: true } });
    },
    distance(lat1, lng1, lat2, lng2) {
      const deg2rad = deg => deg * (Math.PI / 180);

      // Distance between two lat long points
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat1 - lat2); // deg2rad below
      const dLon = deg2rad(lng1 - lng2);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    }
  }
};
</script>
<style lang="scss" scoped>
.air-quality-bg {
  background-image: url('../../../assets/img/factory.jpg');
  background-position: center 65%;
}
.air-quality-rating {
  font-size: 2rem;
  line-height: 54px;
}
</style>
